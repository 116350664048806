<template>
  <div>
    <div class="d-flex flex-row m-3">
      <router-link :to="{name: 'MarketplaceHome'}" class="btn btn-primary me-2">Home</router-link>
      <router-link :to="{name: 'MarketplaceSellItem'}" class="btn btn-primary me-2">Sell</router-link>
      <router-link :to="{name: 'MarketplaceYourListings'}" class="btn btn-primary me-2">Your Listings</router-link>
      <router-link :to="{name: 'MarketplaceHistory'}" class="btn btn-primary me-2">History</router-link>
    </div>

    <router-view />
  </div>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: 'Marketplace',
  mounted() {
  },
  computed: {
    ...mapGetters({
      listings: "marketplace/listings"
    })
  }
}
</script>